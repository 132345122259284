<template>
  <div class="prog-wrap">
    <div class="progress">
      <div class="barOverflow">
        <div
          class="bar"
          :style="{
            transform: 'rotate(' + rotateCircle + 'deg)',
            borderBottomColor: barColor,
            borderRightColor: barColor,
          }"
        ></div>
      </div>
      <span class="light-grey">{{ displayPacing }}</span>
      <div class="light-grey" style="display: inline-block;">%</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanPacingChart',
  props: {
    pacing: {
      type: Number,
      required: true,
      default: () => 0,
    },
  },
  data() {
    return {
      displayPacing: 0,
      rotateCircle: 45,
      barColor: this.getProgressBarColor(),
    };
  },
  watch: {
    pacing() {
      this.change();
    },
  },
  created() {
    this.change();
  },
  methods: {
    change() {
      if (this.displayPacing === this.pacing) return;
      if (this.displayPacing < this.pacing) {
        this.displayPacing++;
        this.rotateCircle = 45 + this.displayPacing * 1.8;
      }
      setTimeout(() => this.change(), 20);
    },
    getProgressBarColor() {
      // *** LOGIC FOR COLOR CHANGE BASED ON PACING ROUND OFF VALUE ***
      // if (this.pacing >= 85) {
      //   return '#54a8c7';
      // } else if (this.pacing >= 65 && this.pacing < 85) {
      //   return '#FDCE07';
      // } else {
      //   return '#D74047';
      // }
      return '#54a8c7';
    },
  },
};
</script>

<style lang="scss" scoped>
.prog-wrap {
  width: 57px;
  margin: auto;
  & .progress {
    position: relative;
    margin: 4px;
    float: left;
    text-align: center;
    top: 15px;
    margin-right: -43px;
    & .barOverflow {
      position: relative;
      overflow: hidden;
      width: 64px;
      height: 30px;
      margin-bottom: -17px;
      margin-top: -28px;
      left: 5px;
      margin-left: -10px;
      & .bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 6px solid rgba(0, 0, 0, 0.07);
        transform: rotate(45deg);
      }
    }
  }
}
</style>
